// import Keyicon from "../assets/keyicon.png";
// import Bagicon from "../assets/ic_bag.png";
// import Earphone from "../assets/genievectors/electronics_vector.jpeg";
// import Passporticon from "../assets/passport_vextor.png";
// import Othericon from "../assets/other.png";
// import Walleticon from "../assets/wallet.png";
// import Remoteicon from "../assets/remot.png";
// import Purseicon from "../assets/purse.png";
// import Laptopicon from "../assets/laptop.png";
// import Cameraicon from "../assets/ic_camer.png";
// import Toyicon from "../assets/ic_toy.png";
// import Cardholder from "../assets/ic_card_holder.png";
// import luggage from "../assets/luggage.png";
// import Backpacs from "../assets/backpack.png";
// import LaptopBag from "../assets/laptopbag.png";
// import Briefcase from "../assets/briefcase.png";
// import CameraBag from "../assets/camerabag.png";
// import GymBag from "../assets/GymBag.png";
// import suitcase from "../assets/suitcase.png";
// import LadiesPurse from "../assets/ladiespurse.png";
// import SportsKitBag from "../assets/sport-bag.png";
// import trolleybag from "../assets/trolley-bag.png";
// import sportskitbag from "../assets/sport-bag.png";
// import schoolbag from "../assets/";
// import other from "../assets/other.png";
// import handbag from "../assets/duffelbag.png";
// import otherkey from "../assets/otherkey.png";
// import carkey from "../assets/vehiclekey.png";
// import camera from "../assets/camera.png";
// import earphone from "../assets/earphone.png";

export function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) === " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

export const PRODUCTCATEGORY_ETAGS = ["E-Passport", "E-Bag"];


//dolphin product category
export const DOLPHIN_PRODUCTS = ["Dolphin Smart Tracker","beacon","Dolphin Pro","Dolphin Eyeware","Dolphin Padlock","Smart Wallet","Dolphin Bag","Dolphin Pro","Dolphin Passport Tracker","Dolphin Camera Kit","Dolphin Smart Wallet","DOLPHIN TAG"]

const MEDICAL_VALUABLE_IMAGE = new Map();
MEDICAL_VALUABLE_IMAGE.set(
  "CPAP",
  "https://storage.googleapis.com/pettag/qr/assets/cpap.png"
);
MEDICAL_VALUABLE_IMAGE.set(
  "Apnea Sleep Holder Mask",
  "https://storage.googleapis.com/pettag/qr/assets/sleepholdermask.png"
);
MEDICAL_VALUABLE_IMAGE.set(
  "Nebulizer",
  "https://storage.googleapis.com/pettag/qr/assets/nebulizer.png"
);
MEDICAL_VALUABLE_IMAGE.set(
  "Diabetes Supplies",
  "https://storage.googleapis.com/pettag/qr/assets/diabetessupplies.png"
);
MEDICAL_VALUABLE_IMAGE.set(
  "Portable Concentrator",
  "https://storage.googleapis.com/pettag/qr/assets/portableconcentrator.png"
);
MEDICAL_VALUABLE_IMAGE.set(
  "Oxygen Unit",
  "https://storage.googleapis.com/pettag/qr/assets/oxygenunit.png"
);
MEDICAL_VALUABLE_IMAGE.set(
  "Respiratory Machines/Accessories",
  "https://storage.googleapis.com/pettag/qr/assets/respiratorymachine.png"
);
MEDICAL_VALUABLE_IMAGE.set(
  "Medical Equipment",
  "https://storage.googleapis.com/pettag/qr/assets/medical-equipment.png"
);

var GenUp = {
  Laptop: "https://storage.googleapis.com/pettag/qr/assets/laptop.png",
  Mobile: "https://storage.googleapis.com/pettag/qr/assets/mobile.png",
  LaptopBag: "https://storage.googleapis.com/pettag/qr/assets/laptopbag.png",
  Mouse: "https://storage.googleapis.com/pettag/qr/assets/mouse.png",
  Camera: "https://storage.googleapis.com/pettag/qr/assets/camera.png",
  Watch: "https://storage.googleapis.com/pettag/qr/assets/watch.png",
  WaterBottle:
    "https://storage.googleapis.com/pettag/qr/assets/waterbottle.png",
  Charger: "https://storage.googleapis.com/pettag/qr/assets/charger.png",
  SchoolBag: "https://storage.googleapis.com/pettag/qr/assets/schoolbag.png",
  SportsKitBag: "https://storage.googleapis.com/pettag/qr/assets/sport-bag.png",
  LadiesPurse: "https://storage.googleapis.com/pettag/qr/handbag.png",
  Eyewear:
    "https://storage.googleapis.com/pettag/qr/assets/eye_glasses_category.png",
};
const lookUp = {
  DOLPHIN : "https://storage.googleapis.com/pettag/qrtagdev/assets/genievectors/Logo.png",
  Eyewear : "https://storage.googleapis.com/pettag/qr/assets/eye_glasses_category.png",
  "SMART WALLET" : "https://storage.googleapis.com/pettag/qr/assets/wallet.png",
  PURSE :  "https://storage.googleapis.com/pettag/qrtagdev/assets/purse.png",
  "CARD HOLDER" : "https://storage.googleapis.com/pettag/qrtagdev/assets/ic_card_holder.png",
  KEY: "https://storage.googleapis.com/pettag/qrtagdev/assets/keyicon.png",
  Bag: "https://storage.googleapis.com/pettag/qr/assets/school_bag_category.png",
  BAG: "https://storage.googleapis.com/pettag/qr/assets/school_bag_category.png",
  EARPHONE: "https://storage.googleapis.com/pettag/qr/assets/earphone.png",
  Headphone:
    "https://storage.googleapis.com/pettag/qr/assets/headphone_category.png",

  PASSPORT: "https://storage.googleapis.com/pettag/qr/assets/passport.png",
  Passport: "https://storage.googleapis.com/pettag/qr/assets/passport.png",
  passport: "https://storage.googleapis.com/pettag/qr/assets/passport.png",
  WALLET: "https://storage.googleapis.com/pettag/qr/assets/wallet_category.png",
  Wallet: "https://storage.googleapis.com/pettag/qr/assets/wallet_category.png",
  REMOTE: "https://storage.googleapis.com/pettag/qrtagdev/assets/remot.png",
  Remote: "https://storage.googleapis.com/pettag/qr/assets/remote_category.png",
  EYEWEAR:
    "https://storage.googleapis.com/pettag/qr/assets/eye_glasses_category.png",
  // "Woment Wallet":"",
  Car: "https://storage.googleapis.com/pettag/qr/assets/purse_category.png",
  LAPTOP: "https://storage.googleapis.com/pettag/qr/assets/laptop_category.png",
  Laptop: "https://storage.googleapis.com/pettag/qr/assets/laptop_category.png",
  CAMERA: "https://storage.googleapis.com/pettag/qr/assets/camera_category.png",
  Camera: "https://storage.googleapis.com/pettag/qr/assets/camera_category.png",
  TOY: "https://storage.googleapis.com/pettag/qr/assets/teddy_bear_category.png",
  Toy: "https://storage.googleapis.com/pettag/qr/assets/teddy_bear_category.png",
  Mouse: "https://storage.googleapis.com/pettag/qr/assets/mouse.png",
  Mobile: "https://storage.googleapis.com/pettag/qr/assets/mobile.png",
  Watch: "https://storage.googleapis.com/pettag/qr/assets/watch.png",
  WaterBottle:
    "https://storage.googleapis.com/pettag/qr/assets/waterbottle.png",
  Charger: "https://storage.googleapis.com/pettag/qr/assets/charger.png",
  Briefcase: "https://storage.googleapis.com/pettag/qr/assets/briefcase.png",
  LaptopBag: "https://storage.googleapis.com/pettag/qr/assets/laptopbag.png",
  CameraBag: "https://storage.googleapis.com/pettag/qr/assets/camerabag.png",
  Luggage: "https://storage.googleapis.com/pettag/qr/assets/luggage.png",
  Backpacs: "https://storage.googleapis.com/pettag/qr/assets/backpack.png",
  GymBag: "https://storage.googleapis.com/pettag/qr/assets/GymBag.png",
  Suitcase: "https://storage.googleapis.com/pettag/qr/assets/suitcase.png",
  LadiesPurse:
    "https://storage.googleapis.com/pettag/qr/assets/ladiespurse.png",
  SportsKitBag: "https://storage.googleapis.com/pettag/qr/assets/sport-bag.png",
  TrolleyBag: "https://storage.googleapis.com/pettag/qr/assets/trolley-bag.png",
  sportskitbag: "https://storage.googleapis.com/pettag/qr/assets/sport-bag.png",
  SchoolBag: "https://storage.googleapis.com/pettag/qr/assets/schoolbag.png",
  DuffelBag: "https://storage.googleapis.com/pettag/qr/assets/duffelbag.png",
  "HandBag/DuffelBag":
    "https://storage.googleapis.com/pettag/qr/assets/duffelbag.png",
  OtherBags: "https://storage.googleapis.com/pettag/qr/assets/other.png",
  CarorBikeKeys:
    "https://storage.googleapis.com/pettag/qr/assets/vehiclekey.png",
  CarorBikeKey:
    "https://storage.googleapis.com/pettag/qr/assets/vehiclekey.png",
  OtherKeys: "https://storage.googleapis.com/pettag/qr/assets/otherkey.png",
  Gadget: "https://storage.googleapis.com/pettag/qr/assets/earphone.png",
  CLB: "https://storage.googleapis.com/pettag/qrtagdev/assets/ic_bag.png",
  GTB: "https://storage.googleapis.com/pettag/qrtagdev/assets/ic_bag.png",
  TWW: "https://storage.googleapis.com/pettag/qr/assets/wallet.png",
  // TWB: "https://storage.googleapis.com/pettag/qrtagdev/assets/ic_bag.png",
  GEN: GenUp,
  CPAP: "https://storage.googleapis.com/pettag/qr/assets/cpap.png",
  ApneaSleepHolderMask:
    "https://storage.googleapis.com/pettag/qr/assets/sleepholdermask.png",
  Nebulizer: "https://storage.googleapis.com/pettag/qr/assets/nebulizer.png",
  DiabetesSupplies:
    "https://storage.googleapis.com/pettag/qr/assets/diabetessupplies.png",
  PortableConcentrator:
    "https://storage.googleapis.com/pettag/qr/assets/portableconcentrator.png",
  OxygenUnit: "https://storage.googleapis.com/pettag/qr/assets/oxygenunit.png",
  "RespiratoryMachines/Accessories":
    "https://storage.googleapis.com/pettag/qr/assets/respiratorymachine.png",
  MedicalEquipment:
    "https://storage.googleapis.com/pettag/qr/assets/medical-equipment.png",
};

export function setIcon(type, number) {
  const tagInitial = number.toUpperCase().substring(0, 3);
  // console.log(type, "type in seticon");
  // console.log(tagInitial, "tagusns");
  const initial = type ? type : tagInitial;
  // console.log(initial, "initial");
  if (tagInitial === "BDT") {
    return (
      lookUp[type.toUpperCase()] ||
      "https://storage.googleapis.com/pettag/qrtagdev/assets/other.png"
    );
  } else {
    // console.log(initial, "initi");
    return (
      lookUp[initial] ||
      "https://storage.googleapis.com/pettag/qrtagdev/assets/other.png"
    );
  }
}

// export const setValuableName = (product_category) => {
// console.log("valu̥ḁble_type", valu̥ḁble_type);
// console.log("cate̥gory", product_category);
// console.log("nḁme", nḁme);
// console.log("nu̥mber", nu̥mber);

// const tagInitial = nḁme.toUpperCase().substring(0, 3) || "";
// console.log("tagInitial", tagInitial);
// if (tagInitial === "GEN") {
// console.log("valu̥ḁble_type", valu̥ḁble_type);

// return valu̥ḁble_type;
// } else {
// return cate̥gory === "beacon" ? name : cate̥gory;
// }
// };

export function setGeneric(type, number) {
  const tagInitial = number.toUpperCase().substring(0, 3);

  if (tagInitial === "GEN") {
    // console.log(type);
    return (
      GenUp[type] ||
      "https://storage.googleapis.com/pettag/qrtagdev/assets/other.png"
    );
  }
}

export default MEDICAL_VALUABLE_IMAGE;
