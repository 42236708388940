import React, { useState, useEffect } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import "./card.component.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { DialogTitle, DialogActions } from "@material-ui/core";

import Tooltip from "@material-ui/core/Tooltip";
import "./keycard.component.css";
import { BsThreeDots } from "react-icons/bs";
import Countdown from "../countdowntimer/Countdown";
import Imagecarousel from "./Imagecarousel";

import { APP_ENV, switchUrls } from "../../api";
import MEDICAL_VALUABLE_IMAGE from "../../utils/setValues";
const gatewayUrl = switchUrls("gateway");
const bag_frontend = switchUrls("bag_frontend");

function AcitveBagCard({
  bag_imagelocation,
  vehicle_brand,
  vehicle_model,
  date_of_purchase,
  valuable_type,
  valuable_id,
  other_nickname,
  bag_brand,
  bag_color,
  description,
  tag_number,
  activation_date,
  tag_status,
  lf_status,
  digital_product,
  journey_startdate,
  journey_enddate,
  deactivation_date,
  no_of_bags,
  orgin,
  destination,
  pnr,
  airLine,
  bag_image1,
  bag_image2,
  medical_tag,
  user_region,
}) {
  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];

      while (c.charAt(0) === " ") c = c.substring(1, c.length);

      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
  }

  let usertoken = readCookie("token");

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [lfstatus, setlfstatus] = useState("lost");
  const [imageLoaded, setImageLoaded] = useState(false);
  const token = usertoken;
  const uid = localStorage.getItem("uid");

  function activateTag() {
    const state = {
      tag_number: tag_number,
    };

    axios
      .post("/api/bagactiveTag", state, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data === "Tag is Activated") {
          window.location.href = "/dashboard/" + uid;
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  function deactivateTag() {
    const state = {
      tag_number: tag_number,
    };

    axios
      .post("/api/bagdeactivateTag", state, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data === "Tag is Deactivated") {
          window.location.href = "/dashboard/" + uid;
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  const downloadQr = async (tag_number) => {
    try {
      setShowLoader(true);
      const ipApiResponse = await fetch("https://ipapi.co/json/");
      const country = await ipApiResponse.json();
      const ipAddress = country.country;
      const { data } = await axios.post(
        `${gatewayUrl}/api/downloadqrtag`,

        { tag_number: tag_number, type: "bag", ip: ipAddress },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          responseType: "blob", // Tell axios to expect binary data
        }
      );

      // Create a download link for the PDF
      const url = window.URL.createObjectURL(
        new Blob([data], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.download = "E-tag.pdf";
      link.click();
      window.URL.revokeObjectURL(url);
      setShowLoader(false);
    } catch (er) {
      console.log(er);
    }
  };

  const handleQrClick = () => {
    window.location.href = `/#/scan/${tag_number}`;
  };
  // define the function that finds the users geolocation
  const getUserLocation = () => {
    // if geolocation is supported by the users browser
    if (navigator.geolocation) {
      // get the current users location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // save the geolocation coordinates in two variables
          const { latitude, longitude } = position.coords;
          axios
            .post(
              "/api/bagreportfoundstatus",
              { tag_number, latitude, longitude },
              {
                headers: {
                  Authorization: token,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              if (res.data?.status) {
                if (APP_ENV === "local") {
                  window.location.href = "/dashboard";
                } else {
                  window.location.href = "/dashboard/" + uid;
                }
              }
            })
            .catch((error) => {
              console.error(error.message);
            });
        },
        // if there was an error getting the users location
        (error) => {
          console.error("Error getting user location:", error);
        },
        { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true }
      );
    }
    // if geolocation is not supported by the users browser
    else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  function reportFound() {
    getUserLocation();
  }
  useEffect(() => {
    // console.log(valuable_type, "type");
    if (valuable_id) {
      switch (valuable_id) {
        case 1:
          setImageSrc(
            "https://storage.googleapis.com/pettag/qr/assets/laptopbag.png"
          );
          break;
        case 2:
          setImageSrc(
            "https://storage.googleapis.com/pettag/qr/assets/briefcase.png"
          );
          break;
        case 3:
          setImageSrc(
            "https://storage.googleapis.com/pettag/qr/assets/camerabag.png"
          );
          break;
        case 4:
          setImageSrc(
            "https://storage.googleapis.com/pettag/qr/assets/GymBag.png"
          );
          break;
        case 5:
          setImageSrc(
            "https://storage.googleapis.com/pettag/qr/assets/trolley-bag.png"
          );
          break;
        case 6:
          setImageSrc(
            "https://storage.googleapis.com/pettag/qr/assets/suitcase.png"
          );
          break;
        case 7:
          setImageSrc(
            "https://storage.googleapis.com/pettag/qr/assets/ladiespurse.png"
          );
          break;
        case 8:
          setImageSrc(
            "https://storage.googleapis.com/pettag/qr/assets/sport-bag.png"
          );
          break;
        case 9:
          setImageSrc(
            "https://storage.googleapis.com/pettag/qr/assets/duffelbag.png"
          );
          break;
        case 10:
          setImageSrc(
            "https://storage.googleapis.com/pettag/qr/assets/other.png"
          );
          break;
        case 11:
          setImageSrc(
            "https://storage.googleapis.com/pettag/qr/assets/schoolbag.png"
          );
          break;
        case 12:
          setImageSrc(
            "https://storage.googleapis.com/pettag/qr/assets/luggage.png"
          );
          break;
        default:
          return null;
      }
    } else {
      switch (valuable_type) {
        case "Luggage":
          setImageSrc(
            "https://storage.googleapis.com/pettag/qr/assets/luggage.png"
          );
          break;
        case "Backpacs":
          setImageSrc(
            "https://storage.googleapis.com/pettag/qr/assets/backpack.png"
          );
          break;
        case "LaptopBag":
        case "Laptop Bag":
          setImageSrc(
            "https://storage.googleapis.com/pettag/qr/assets/laptopbag.png"
          );
          break;
        case "Briefcase":
          setImageSrc(
            "https://storage.googleapis.com/pettag/qr/assets/briefcase.png"
          );
          break;
        case "CameraBag":
        case "Camera Bag":
          setImageSrc(
            "https://storage.googleapis.com/pettag/qr/assets/camerabag.png"
          );
          break;
        case "GymBag":
        case "Gym Bag":
          setImageSrc(
            "https://storage.googleapis.com/pettag/qr/assets/GymBag.png"
          );
          break;
        case "Suitcase":
          setImageSrc(
            "https://storage.googleapis.com/pettag/qr/assets/suitcase.png"
          );
          break;
        case "LadiesPurse":
        case "Ladies Purse":
          setImageSrc(
            "https://storage.googleapis.com/pettag/qr/assets/ladiespurse.png"
          );
          break;
        case "SportsKitBag":
        case "Sports Kit Bag":
          setImageSrc(
            "https://storage.googleapis.com/pettag/qr/assets/sport-bag.png"
          );
          break;
        case "DuffelBag":
        case "Hand Bag/Duffel Bag":
          setImageSrc(
            "https://storage.googleapis.com/pettag/qr/assets/duffelbag.png"
          );
          break;
        case "SchoolBag":
        case "School Bag":
          setImageSrc(
            "https://storage.googleapis.com/pettag/qr/assets/schoolbag.png"
          );
          break;
        case "TrolleyBag":
        case "Trolley bag":
          setImageSrc(
            "https://storage.googleapis.com/pettag/qr/assets/trolley-bag.png"
          );
          break;

        case "OtherBags":
        case "Other Bags":
          setImageSrc(
            "https://storage.googleapis.com/pettag/qr/assets/other.png"
          );
          break;
        default:
          return null;
      }
    }
  }, []);

  const convertISOStringToMonthDay = (date) => {
    if (digital_product && user_region) {
      const tempDate = new Date(date);

      // Format the date directly in the user's local time zone
      const day = tempDate.toLocaleString("en-US", {
        day: "2-digit",
        timeZone: user_region,
      });
      const month = tempDate.toLocaleString("en-US", {
        month: "short",
        timeZone: user_region,
      });
      const year = tempDate.toLocaleString("en-US", {
        year: "numeric",
        timeZone: user_region,
      });
      const formattedDate = `${day} ${month}, ${year}`;
      return formattedDate;
    } else {
      const tempDate = new Date(date);
      const day = tempDate.getUTCDate().toString().padStart(2, "0");
      const month = tempDate.toLocaleString("default", { month: "short" });
      const year = tempDate.getUTCFullYear();
      const formattedDate = `${day} ${month}, ${year}`;
      return formattedDate;
    }
  };

  useEffect(() => {
    if (bag_imagelocation || bag_image1 || bag_image2) {
      const img = new Image();
      if (bag_imagelocation) {
        img.src = bag_imagelocation;
        img.onload = () => {
          setImageLoaded(true);
        };
      } else if (bag_image1) {
        img.src = bag_image1;
        img.onload = () => {
          setImageLoaded(true);
        };
      } else {
        img.src = bag_image2;
        img.onload = () => {
          setImageLoaded(true);
        };
      }

      return () => {
        img.onload = null;
      };
    }
  }, [bag_imagelocation]);
  useEffect(() => {
    if (imageSrc) {
      const img = new Image();
      img.src = imageSrc;
      img.onload = () => {
        setImageLoaded(true);
      };
      return () => {
        img.onload = null;
      };
    }
  }, [imageSrc]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose1 = () => {
    setShow(false);
  };
  // const handleImageLoad = () => {
  //   setImageLoaded(true);
  // };
  function foundOrLost() {
    if (lf_status === "lost") {
      setShow2(true);

      handleClose();
    } else {
      const redirectLink = "/bag/report-lost/" + tag_number;

      window.location.href = redirectLink;
    }
  }

  return (
    <div className={digital_product ? "card_main_digital" : "card_main"}>
      <div
        className={digital_product ? "card_main_div_digital" : "card_main_div"}
      >
        <div
          className="imagedetails"
         
        >
          <div>
            
              <div
                style={{
                  display: imageLoaded ? "none" : "block",
                  width: "100px",
                  height: "100px",
                }}
              >
                <img
                  src="https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                  alt="Loading..."
                  loading="lazy"
                  style={{ width: "100px", height: "100px" }}
                />
              </div>
            

            {digital_product ? (
              <>
                {no_of_bags === 2 && bag_image1 && bag_image2 ? (
                  <Imagecarousel
                    bag_image1={bag_image1}
                    bag_image2={bag_image2}
                  />
                ) : (
                  <>
                    <img
                      src={
                        bag_image1
                          ? bag_image1
                          : bag_image2
                          ? bag_image2
                          : imageSrc
                      }
                      alt="bag"
                      style={{
                        width: 100,
                        height: 100,
                        display: imageLoaded ? "block" : "none",
                      }}
                      //onLoad={handleImageLoad}
                      loading="lazy"
                    />
                  </>
                )}
              </>
            ) : medical_tag ? (
              <>
                <img
                  src={
                    bag_imagelocation
                      ? bag_imagelocation
                      : MEDICAL_VALUABLE_IMAGE.get(valuable_type)
                  }
                  alt="bag"
                  style={{
                    width: 100,
                    height: 100,
                    display: imageLoaded ? "block" : "none",
                  }}
                  //onLoad={handleImageLoad}
                  loading="lazy"
                />
              </>
            ) : (
              <img
                src={bag_imagelocation ? bag_imagelocation : imageSrc}
                alt="bag"
                style={{
                  width: 100,
                  height: 100,
                  display: imageLoaded ? "block" : "none",
                }}
                //onLoad={handleImageLoad}
                loading="lazy"
              />
            )}

            <div className="qrscan-img">
              <img
                alt="qr"
                src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                style={{ width: "20px", height: "20px" }}
              />
              <p style={{ fontSize: "0.85rem" }}>
                <b>{tag_number && tag_number !== "-" ? tag_number : "N/A"}</b>
              </p>
            </div>

          

            {digital_product ? (
              <div className="mobile-view">
                <ul
                  className="tagdetails"
                  style={{
                    listStyle: "none",
                    color: "black",
                    textAlign: "right",
                    marginRight: 20,
                    fontSize: "12px",
                    lineHeight: "normal",
                  }}
                >
                  {/* <li className="petownerid">
                <span>{tag_number}</span>
              </li> */}
                  {lf_status === "lost" ? (
                    <li className="digital_card_mobile-view-lostpetstatus">
                      <span>Reported Lost</span>
                    </li>
                  ) : (
                    <li
                      className={
                        digital_product
                          ? "petstatus_digital"
                          : "petstatus mobile-view-petstatus"
                      }
                    >
                      {digital_product ? (
                        <>
                          <Tooltip
                            title={
                              tag_status === "inactive"
                                ? "Tag will activate Soon"
                                : tag_status === "deactive"
                                ? "Tag Deactivated"
                                : tag_status === "active"
                                ? "Tag is Active"
                                : "Unknown Tag Status"
                            }
                            aria-label="add"
                          >
                            <button
                              className={
                                tag_status === "active"
                                  ? "activatedeactivatebutton bg-success mt-4"
                                  : "activatedeactivatebutton bg-danger mt-4"
                              }
                              style={{ marginRight: "4px" }}
                            ></button>
                          </Tooltip>
                          {tag_status !== "inactive" ? (
                            <>
                              <span>{`${
                                tag_status.charAt(0).toUpperCase() +
                                tag_status.slice(1)
                              }`}</span>
                            </>
                          ) : (
                            <span>Inactive</span>
                          )}
                        </>
                      ) : (
                        <>
                          {tag_status === "active" ? (
                            <Tooltip
                              title="Click here to deactivate tag"
                              aria-label="add"
                            >
                              <button
                                className="activatedeactivatebutton bg-success mt-4"
                                onClick={() => {
                                  setShow(true);
                                  handleClose();
                                }}
                                style={{ marginRight: "4px" }}
                              ></button>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title="Click here to activate tag"
                              aria-label="add"
                            >
                              <button
                                className="activatedeactivatebutton bg-danger mt-4"
                                onClick={() => {
                                  setShow(true);
                                  handleClose();
                                }}
                                style={{ marginRight: "4px" }}
                              ></button>
                            </Tooltip>
                          )}
                        </>
                      )}
                    </li>
                  )}
                </ul>
              </div>
            ) : (
              <>
                <div className="mobile-view">
                  <ul
                    className="tagdetails"
                    style={{
                      listStyle: "none",
                      color: "black",
                      textAlign: "right",
                      marginRight: 20,
                      fontSize: "12px",
                      lineHeight: "normal",
                    }}
                  >
                    {/* <li className="petownerid">
                  <span>{tag_number}</span>
                </li> */}
                    {lf_status === "lost" ? (
                      <li className="petloststatus mobile-view-lostpetstatus">
                        <span>Reported Lost</span>
                      </li>
                    ) : (
                      <li className="petstatus mobile-view-petstatus">
                        {digital_product ? (
                          <>
                            <Tooltip
                              title={
                                tag_status === "inactive"
                                  ? "Tag will activate Soon"
                                  : tag_status === "deactive"
                                  ? "Tag Deactivated"
                                  : tag_status === "active"
                                  ? "Tag is Active"
                                  : "Tag Expired"
                              }
                              aria-label="add"
                            >
                              <button
                                className={
                                  tag_status === "active"
                                    ? "activatedeactivatebutton bg-success mt-4"
                                    : "activatedeactivatebutton bg-danger mt-4"
                                }
                                style={{ marginRight: "4px" }}
                              ></button>
                            </Tooltip>
                            <span>{`${
                              tag_status.charAt(0).toUpperCase() +
                              tag_status.slice(1)
                            }`}</span>
                          </>
                        ) : (
                          <>
                            {tag_status === "active" ? (
                              <Tooltip
                                title="Click here to deactivate tag"
                                aria-label="add"
                              >
                                <button
                                  className="activatedeactivatebutton bg-success mt-4"
                                  onClick={() => {
                                    setShow(true);
                                    handleClose();
                                  }}
                                  style={{ marginRight: "4px" }}
                                ></button>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title="Click here to activate tag"
                                aria-label="add"
                              >
                                <button
                                  className="activatedeactivatebutton bg-danger mt-4"
                                  onClick={() => {
                                    setShow(true);
                                    handleClose();
                                  }}
                                  style={{ marginRight: "4px" }}
                                ></button>
                              </Tooltip>
                            )}
                            <span>{`${
                              tag_status.charAt(0).toUpperCase() +
                              tag_status.slice(1)
                            }`}</span>
                          </>
                        )}
                      </li>
                    )}
                    {/* <li className="petactivationdate mobile-view-petactivationdate passacticationdate">
                    <span className="activedate-lebal">Active Date: </span>
                    <span className="">
                      {convertISOStringToMonthDay(activation_date)}
                    </span>
                  </li> */}
                  </ul>
                </div>
              </>
            )}
           <div className="mobile-view">
           {digital_product &&
            journey_startdate &&
            journey_enddate &&
            tag_status === "inactive" ? (
              <>
                <Countdown travelstartDate={journey_startdate} type={"bag"} />
              </>
            ) : null}
           </div>
          </div>
        </div>
        <div
          className={
            digital_product ? "middle-content_digital" : "middle-content"
          }
        >
          <ul
            // className="bagcardtext"
            // style={{
            //   textAlign: "left",
            //   listStyle: "none",
            //   display: "inline-block",
            //   color: "black",
            //   marginLeft: 5,
            //   lineHeight: "normal",
            //   fontSize: "16px",
            // }}
            className="geniecardtext-ul"
          >
            {digital_product && (
              <>
                {no_of_bags && (
                  <li style={{ display: "none" }} className="petdetails">
                    <span
                      className={digital_product ? "digital_lebal" : "lebal"}
                    >
                      No Of Bags:{" "}
                    </span>
                    <span
                      className={
                        digital_product
                          ? "digital_lebal-content"
                          : "lebal-content"
                      }
                    >
                      {no_of_bags}
                    </span>
                  </li>
                )}
                {pnr && (
                  <li className="petdetails">
                    <span
                      className={digital_product ? "digital_lebal" : "lebal"}
                    >
                      PNR:{" "}
                    </span>
                    <span
                      className={
                        digital_product
                          ? "digital_lebal-content"
                          : "lebal-content"
                      }
                    >
                      {pnr}
                    </span>
                  </li>
                )}
                {airLine && (
                  <li className="petdetails">
                    <span
                      className={digital_product ? "digital_lebal" : "lebal"}
                    >
                      Airline:{" "}
                    </span>
                    <span
                      className={
                        digital_product
                          ? "digital_lebal-content"
                          : "lebal-content"
                      }
                    >
                      {airLine}
                    </span>
                  </li>
                )}
                {destination && orgin && (
                  <li className="petdetails">
                    <span
                      className={digital_product ? "digital_lebal" : "lebal"}
                    >
                      Journey:{" "}
                    </span>
                    <span
                      className={
                        digital_product
                          ? "digital_lebal-content"
                          : "lebal-content"
                      }
                    >
                      {orgin}
                      {"-"}
                      {destination}
                    </span>
                  </li>
                )}
              </>
            )}
            {valuable_type ? (
              <li className="petdetails">
                <span className={digital_product ? "digital_lebal" : "lebal"}>
                  Valuable Type:{" "}
                </span>
                <span
                  className={
                    digital_product ? "digital_lebal-content" : "lebal-content"
                  }
                >
                  {valuable_type}
                </span>
              </li>
            ) : null}
            {description ? (
              <li className="petdetails">
                <span className="lebal">Description: </span>
                <span className="lebal-content">
                  {description?.slice(0, 35)}
                  {description?.length > 35 ? (
                    <BsThreeDots className="mt-1" />
                  ) : (
                    ""
                  )}
                </span>
              </li>
            ) : (
              ""
            )}
            {bag_color ? (
              <li className="petdetails">
                <span className="lebal">Bag Color: </span>
                <span className="lebal-content">{bag_color}</span>
              </li>
            ) : (
              ""
            )}
            {bag_brand ? (
              <li className="petdetails">
                <span className="lebal">Bag Brand: </span>
                <span className="lebal-content">
                  {bag_brand?.slice(0, 35)}
                  {bag_brand?.length > 35 ? (
                    <BsThreeDots className="mt-1" />
                  ) : (
                    ""
                  )}
                </span>
              </li>
            ) : (
              ""
            )}
            {date_of_purchase ? (
              <li className="petdetails">
                <span className="lebal">Purchase Date: </span>
                <span className="lebal-content">
                  {convertISOStringToMonthDay(date_of_purchase)}
                </span>
              </li>
            ) : (
              ""
            )}
            {journey_startdate && journey_enddate ? (
              <li className="petdetails">
                <span className={digital_product ? "digital_lebal" : "lebal"}>
                  Travel Date:{" "}
                </span>
                <span
                  className={
                    digital_product ? "digital_lebal-content" : "lebal-content"
                  }
                >
                  {convertISOStringToMonthDay(journey_startdate)} -{" "}
                  {convertISOStringToMonthDay(journey_enddate)}
                </span>
              </li>
            ) : (
              ""
            )}
            {activation_date && tag_status === "active" ? (
              <>
                <li className="petdetails date-laptopview">
                  <span className="lebal">Active Date: </span>
                  <span
                    span
                    className="lebal-content"
                  >{` ${convertISOStringToMonthDay(activation_date)}`}</span>
                </li>
              </>
            ) : null}
          </ul>
          <ul
            className="tagdetails laptop-view"
            style={{
              listStyle: "none",
              color: "black",
              textAlign: "right",
              marginRight: digital_product ? 0 : 20,
              // fontSize: "12px",
              lineHeight: "normal",
            }}
          >
            {/* <li className="petownerid">
              <span>{tag_number}</span>
            </li> */}
            {lf_status === "lost" ? (
              <li className="petloststatus mt-3">
                <span>Reported Lost</span>
              </li>
            ) : (
              <li className="petstatus">
                {digital_product ? (
                  <>
                    <Tooltip
                      title={
                        tag_status === "inactive"
                          ? "Tag will activate Soon"
                          : tag_status === "deactive"
                          ? "Tag Deactivated"
                          : tag_status === "active"
                          ? "Tag is Active"
                          : "Tag Expired"
                      }
                      aria-label="add"
                    >
                      <button
                        className={
                          tag_status === "active"
                            ? "activatedeactivatebutton bg-success mt-4"
                            : "activatedeactivatebutton bg-danger mt-4"
                        }
                        style={{ marginRight: "4px" }}
                      ></button>
                    </Tooltip>
                    {tag_status === "active" ? (
                      <>
                        <span>{`${
                          tag_status.charAt(0).toUpperCase() +
                          tag_status.slice(1)
                        }`}</span>
                      </>
                    ) : (
                      <span>Inactive</span>
                    )}
                  </>
                ) : (
                  <>
                    {tag_status === "active" ? (
                      <Tooltip
                        title="Click here to deactivate tag"
                        aria-label="add"
                      >
                        <button
                          className="activatedeactivatebutton bg-success mt-4"
                          onClick={() => {
                            setShow(true);
                            handleClose();
                          }}
                          style={{ marginTop: 15, marginRight: 1 }}
                        ></button>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title="Click here to activate tag"
                        aria-label="add"
                      >
                        <button
                          className="activatedeactivatebutton bg-danger mt-4"
                          onClick={() => {
                            setShow(true);
                            handleClose();
                          }}
                          style={{ marginTop: 15, marginRight: 1 }}
                        ></button>
                      </Tooltip>
                    )}
                    <span>{`${
                      tag_status.charAt(0).toUpperCase() + tag_status.slice(1)
                    } `}</span>
                  </>
                )}
              </li>
            )}
            {digital_product ? (
              <>
                {tag_status === "inactive" ? <>

                  
                  {digital_product &&
            journey_startdate &&
            journey_enddate &&
            tag_status === "inactive" && 
              
              <li style={{marginTop:"1rem"}}>
                
                <Countdown travelstartDate={journey_startdate} type={"bag"} />
                
           
              </li>
                  }
                  
                </> : (
                  <li className="petactivationdate">
                    {tag_status === "active" ? (
                      <>
                        <span className="petactive-lebal">Active Date</span>
                        <span
                          className={
                            digital_product
                              ? "digital_lebal-content"
                              : "lebal-content"
                          }
                        >{` ${convertISOStringToMonthDay(
                          activation_date
                        )}`}</span>
                      </>
                    ) : (
                      <>
                        {deactivation_date && (
                          <>
                            <span className="petactive-lebal">
                              Deactivated On:
                            </span>
                            <span
                              className={
                                digital_product
                                  ? "digital_lebal-content"
                                  : "lebal-content"
                              }
                            >{` ${convertISOStringToMonthDay(
                              deactivation_date
                            )}`}</span>
                          </>
                        )}
                      </>
                    )}
                  </li>
                )}
              </>
            ) : (
              <>
                <li className="petactivationdate">
                  <span className="petactive-lebal">Active Date:</span>
                  <span className="lebal-content">{` ${convertISOStringToMonthDay(
                    activation_date
                  )}`}</span>
                  {digital_product && tag_status === "deactive" ? (
                    <>
                      <li className="petactivationdate">
                        <span className="petactive-lebal">Deactivated On:</span>
                        <span className="lebal-content">{` ${convertISOStringToMonthDay(
                          activation_date
                        )}`}</span>
                      </li>
                    </>
                  ) : null}
                </li>
              </>
            )}
          </ul>
        </div>
        <div
          className={
            digital_product
              ? "tagdetailsandicons_digital"
              : "tagdetailsandicons"
          }
        >
          <div className="icons" style={{ marginRight: "30px" }}>
            
          {digital_product &&
              (tag_status === "active" || tag_status === "inactive") ? (
                <>
                  <Tooltip title="Download eTag" aria-label="add">
                    <p style={{cursor:"pointer"}}  onClick={()=>downloadQr(tag_number)}>
                      <img
                        className="images-css"
                        src={"https://storage.googleapis.com/pettag/qr/assets/icondownload.png"}
                        alt="Pet Icon"
                      />
                    </p>
                  </Tooltip>
                </>
              ) : null}
            <Tooltip title="Click here to try QR Scan" aria-label="add">
              <div onClick={handleQrClick} style={{ cursor: "pointer" }}>
                <img
                  className="images-css"
                  src={"https://storage.googleapis.com/pettag/qr/assets/qr.png"}
                  alt="Pet Icon"
                />
              </div>
            </Tooltip>
          
            {tag_status === "active" || tag_status === "inactive"? (
              <>
                <Tooltip title="Bag Details" aria-label="add">
                  <a
                    onClick={() => {
                      setShowLoader(true);
                      setTimeout(() => {
                        setShowLoader(false);
                      }, 5000);
                      window.location.href = "/bag-details/" + tag_number;
                      //window.location.href =  bag_frontend +"/#/bag-details/" + tag_number;
                    }}
                  >
                  
                    <img
                      className="images-css"
                      src={
                        "https://storage.googleapis.com/pettag/qr/assets/edits.png"
                      }
                      alt="Pet Icon"
                    />
                  </a>
                </Tooltip>
                <i
                  aria-controls="menu-list-grow"
                  onClick={handleClick}
                  className="fa fa-ellipsis-v menu-drop"
                  style={{
                    color: "#ffc200f7",
                    fontSize: 27,
                    marginBottom: 1,
                    // marginRight: "-40px",
                    // marginLeft: "-10px",
                    // paddingLeft: "10px",
                    // paddingRight: "10px",
                  }}
                ></i>
              </>
            ) : null}

      




           
         {
          tag_status === "active" || tag_status === "inactive" ? <>
             <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {!digital_product && (
                <MenuItem
                  onClick={() => {
                    if (lf_status === "lost") {
                      setShow2(true);

                      handleClose();
                    } else {
                      // window.location.href = "/bag/report-lost/" + tag_number;
                      axios
                        .post(
                          "/api/bagreportlost/" + tag_number,
                          { lfstatus },
                          {
                            headers: { Authorization: token },
                          }
                        )
                        .then((response) => {
                          // console.log(response);
                          // console.log(response.data);
                          if (response.data === "Tag is reported Lost") {
                            window.location.href =
                              "/dashboard/" + localStorage.getItem("uid");
                          }
                        })
                        .catch((error) => {
                          // console.log(error);

                          this.setState({
                            snackbaropen: true,
                            snackbarmsg: "Report Lost Failed",
                          });
                        });
                      setShowLoader(true);
                      setTimeout(() => {
                        setShowLoader(false);
                      }, 5000);
                    }
                  }}
                >
                  {lf_status === "lost" ? "Report Found" : "Report Lost"}
                </MenuItem>
              )}

              {!digital_product && (
                <MenuItem
                  onClick={() => {
                    setShow(true);
                    handleClose();
                  }}
                >
                  {tag_status === "active" ? "Deactivate Tag" : "Activate Tag"}
                </MenuItem>
              )}

              {tag_status === "active" && tag_status !== "cancel" && (
                <MenuItem
                  onClick={() => {
                    setShowLoader(true);
                    setTimeout(() => {
                      setShowLoader(false);
                    }, 5000);

                    window.location.href = "/bag-details/" + tag_number;
                    //window.location.href =  bag_frontend +"/#/bag-details/" + tag_number;
                  }}
                >
                  Bag Details
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  window.location.href = "/#/scan/" + tag_number;
                }}
              >
                Preview QR Scan
              </MenuItem>
              {digital_product && (
                <MenuItem
                  onClick={() => {
                    downloadQr(tag_number);
                  }}
                >
                  Download eTag
                </MenuItem>
              )}
              {digital_product && tag_status === "active" &&
                (lf_status === "lost" ? (
                  <MenuItem
                    onClick={() => {
                      window.location.href =
                        bag_frontend +
                        "/#" +
                        `/bag/edit-report-lost/${tag_number}`;
                    }}
                  >
                    Edit Claim
                  </MenuItem>
                ) : (
                  <MenuItem
                    onClick={() => {
                      window.location.href =
                        bag_frontend + "/#" + `/bag/report-lost/${tag_number}`;
                    }}
                  >
                    Report Claim
                  </MenuItem>
                ))}
            </Menu>
          </> : null
         }

      
            <Dialog
              open={show}
              onClose={handleClose1}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <h1
                  style={{
                    margin: "20px",
                    marginTop: "10px",
                    font: "500 20px/32px Roboto,Helvetica Neue,sans-serif",
                    letterSpacing: "normal",
                  }}
                >
                  {tag_status === "active"
                    ? "Are you sure you want to deactivate the tag?"
                    : "Are you sure you want to activate the tag?"}
                </h1>

                <div style={{ display: "flex" }}>
                  <button
                    onClick={
                      tag_status === "active" ? deactivateTag : activateTag
                    }
                    className="activeCard__confirm"
                    style={{ margin: "10px 20px" }}
                  >
                    CONFIRM
                  </button>

                  <button
                    onClick={() => setShow(false)}
                    className="activeCard__cancel"
                    style={{ margin: "10px 20px" }}
                  >
                    CANCEL
                  </button>
                </div>
              </DialogContent>
            </Dialog>
            <Dialog
              open={show2}
              onClose={handleClose1}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <h1
                  style={{
                    margin: "20px",
                    marginTop: "10px",
                    font: "500 20px/32px Roboto,Helvetica Neue,sans-serif",
                    letterSpacing: "normal",
                  }}
                >
                  Are you sure you want to report found?
                </h1>

                <div style={{ display: "flex" }}>
                  <button
                    onClick={reportFound}
                    className="activeCard__confirm"
                    style={{ margin: "10px 20px" }}
                  >
                    CONFIRM
                  </button>

                  <button
                    onClick={() => setShow2(false)}
                    className="activeCard__cancel"
                    style={{ margin: "10px 20px" }}
                  >
                    CANCEL
                  </button>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>

      {digital_product && journey_startdate && journey_enddate ? (
        <>
          <div className="digital_icon" style={{ overflow: "hidden" }}>
            <div
              className="icons"
              style={{
                marginLeft: "15px",
                float: "right",
                paddingTop: "1rem",
                paddingRight: "1rem",
              }}
            >
                 {digital_product &&
              (tag_status === "active" || tag_status === "inactive") ? (
                <>
                  <Tooltip title="Download eTag" aria-label="add">
                    <p style={{cursor:"pointer"}}  onClick={()=>downloadQr(tag_number)}>
                      <img
                        className="images-css"
                        src={"https://storage.googleapis.com/pettag/qr/assets/icondownload.png"}
                        alt="Pet Icon"
                      />
                    </p>
                  </Tooltip>
                </>
              ) : null}
              <Tooltip title="Click here to try QR Scan" aria-label="add">
                <div onClick={handleQrClick}>
                  <img
                    className="images-css"
                    src={
                      "https://storage.googleapis.com/pettag/qr/assets/qr.png"
                    }
                    alt="Pet Icon"
                  />
                </div>
              </Tooltip>
            

              {tag_status === "active" || tag_status === "inactive" ? (
                <>
                
                  <Tooltip title="passportdetails" aria-label="add">
                    <a
                      onClick={() => {
                        setShowLoader(true);
                        setTimeout(() => {
                          setShowLoader(false);
                        }, 5000);
                        window.location.href =
                          "/passport-details/" + tag_number;
                      }}
                    >
                 
                      <img
                        className="images-css"
                        src={
                          "https://storage.googleapis.com/pettag/qr/assets/edits.png"
                        }
                        alt="Pet Icon"
                      />
                    </a>
                  </Tooltip>
             
                </>
              ) : null}


              
              {tag_status === "active" || tag_status === "inactive" ? (
                <>
                  <i
                    aria-controls="menu-list-grow"
                    onClick={handleClick}
                    className="fa fa-ellipsis-v menu-drop"
                    style={{
                      color: "#ffc200f7",
                      fontSize: 27,
                      marginBottom: 1,
                      // marginRight: "-40px",
                      // marginLeft: "-10px",
                      // paddingLeft: "10px",
                      // paddingRight: "10px",
                    }}
                  ></i>
                </>
              ) : null}
             
           

              <Dialog
                PaperProps={{
                  style: { borderRadius: 15 },
                }}
                open={show}
                onClose={handleClose1}
                // aria-labelledby="form-dialog-title"
              >
                <DialogTitle style={{ fontFamily: "Prompt" }}>
                  {tag_status === "active"
                    ? "Are you sure you want to deactivate the tag?"
                    : "Are you sure you want to activate the tag?"}
                </DialogTitle>

                <DialogActions style={{ margin: "0px 1em 1em 1em" }}>
                  <button
                    // onClick={tag_status === "active" ? deactivateTag : activateTag}
                    //onClick={handleConfirm}
                    className="activeCard__confirm"
                  >
                    Confirm
                  </button>

                  <button
                    onClick={() => setShow(false)}
                    className="activeCard__cancel"
                  >
                    Cancel
                  </button>
                </DialogActions>
              </Dialog>
              <Dialog
                PaperProps={{
                  style: { borderRadius: 15 },
                }}
                open={show2}
                onClose={handleClose1}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle>
                  {"Are you sure you want to report found?"}
                </DialogTitle>

                <DialogActions style={{ margin: "0px 1em 1em 1em" }}>
                  <button onClick={reportFound} className="activeCard__confirm">
                    Confirm
                  </button>

                  <button
                    onClick={() => setShow2(false)}
                    className="activeCard__cancel"
                  >
                    Cancel
                  </button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </>
      ) : null}

      {showLoader && (
        <div className="loader-container">
          <div className="loader">
            <img
              src={"https://storage.googleapis.com/pettag/qr/assets/loader.gif"}
              alt="Loading..."
              loading="lazy"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default AcitveBagCard;
